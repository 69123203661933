.body {
  display: flex;
  flex-wrap: wrap;
  min-width: 820px;
  margin: 0 auto;
  padding: 0 15px;
}

.content {
  padding-top: 15px;
}
