.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
