@import "src/_ui.scss";

.selector {
  display: flex;
  align-items: center;
  flex-basis: 720px;
  width: 720px;
}

.submit {
  margin: 0 5px;
  @include button;
  flex-basis: fit-content;
}

.reset {
  @include button-tertiary;
  flex-basis: fit-content;
}
