.panel {
  display: flex;
  justify-content: space-between;
  width: 1500px;
  flex-wrap: wrap;
  align-items: center;
}

.panel > div {
  margin: 7px 0;
}

.panel .from_station {
  flex-grow: 0;
  min-width: 493px;
}

.panel .to_station {
  flex-grow: 1;
  margin-left: 50px;
  flex-basis: 500px;
}
