@import "src/_ui.scss";

.price {
  text-align: right;
  display: block;
  margin-bottom: 2px;
  white-space: nowrap;
}

.input,
.value {
  font-size: 1.3em;
  height: 20px;
  border: 1px solid transparent;
  line-height: 20px;
  padding: 0 2px;
  display: inline-block;
  vertical-align: top;
  text-align: right;
}


.input {
  min-width: 45px;
  width: 51px;
  background-color: lightyellow;
  border: 1px dashed gold !important;
  margin: 0;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.unsaved {
  background-color: $unsaved-color;
}

.currency {
  display: inline-block;
  width: 24px;
  text-align: right;
  color: #808080;
}

.editing {
  margin-left: 4px;
  vertical-align: top;
}
