@import "src/ui";

.station {
  display: flex;
}

.code {
  border-radius: 3px;
  padding: 1px 5px;
  font-size: 12px;
  box-sizing: border-box;
  cursor: default;
  font-weight: 700;
  height: auto;
  margin-right: 5px;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  background-color: $light-green;
  color: #fff;
  vertical-align: middle;
  width: 38px;
}

.name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  font-weight: 400;
  height: auto;
  line-height: 21px;
  text-align: left;
  width: auto;
  padding-top: 1px;
}
