@import "src/_ui.scss";

.directionRules {
  font-size: 14px;
  label {
    cursor: pointer;
  }
  margin-right: 10px;
}

.inline {
  display: inline-block;
}

.error {
  color: red;
}
