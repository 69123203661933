.selector {
  display: flex;
  margin: 0 5px;
  align-items: center;
  flex-basis: 500px;
}
.selector > * {
  margin: 0 2px;
}

.label {
  flex-basis: fit-content;
}
