.class {
  text-align: right;
  display: block;
  margin-bottom: 2px;
  white-space: nowrap;
  color: #444;
}

.label {
  display: inline-block;
  width: 20px;
  padding-top: 1px;
  vertical-align: top;
  margin-right: 2px;
}

.value {
  font-size: 1.3em;
  height: 20px;
  border: 1px solid transparent;
  line-height: 20px;
  padding: 0 2px;
  display: inline-block;
  vertical-align: top;
  text-align: right;
}

.input {
  writing-mode: horizontal-tb;
  min-width: 16px;
  text-align: center;
  padding: 0 2px;
  width: 25px;
  background-color: lightyellow;
  border: 1px dashed gold !important;
  margin: 0;


  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
}

.unsaved {
  background-color: #d1eed1;
}
