@import "src/_ui.scss";

.edit {
  @include button-primary;
}

.save {
  @include button-primary;
}

.cancel {
  @include button-tertiary;
}

.mirror {
  font-size: 14px;
  label {
    cursor: pointer;
  }
}

.auto_convert {
  font-size: 14px;
  label {
    cursor: pointer;
  }
}

.directionRules {
  font-size: 14px;
  label {
    cursor: pointer;
  }
}

.inline {
  display: inline-block;
}
