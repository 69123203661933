.selector {
  display: flex;
  align-items: center;
}

// .Select-control
.selector > div {
  min-height: 37px;
}

.selector > * {
  margin: 0 2px;
}

.label {
  flex-basis: fit-content;
}
