$unsaved-color: #d1eed1;
$inactive-opacity: 0.4;
$light-green: #73d700;
$error-color: #ff5700;

@mixin button {
  font-size: 16px;
  border: 0;
  border-radius: 5px;
  background: $light-green;
  color: #fff;
  padding: 8px 15px;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: #6bbb00;
  }

  >svg {
    margin-right: 3px;
  }
}

@mixin button-primary {
  @include button;
  background: #ffad00;
  color: #fff;
  &:hover {
    background: #c68600;
    color: #fff;
  }
}

@mixin button-tertiary {
  @include button;
  border: 1px solid $light-green;
  background: #fff;
  color: $light-green;
  padding: 7px 15px;
  &:hover {
    background-color: #88db00;
    color: #fff;
  }
}
