@import "src/ui";

.empty {
  color: #808080;
  background-color: #f5f5f5;
  text-align: center;
  vertical-align: middle;
}

.inactive {
  opacity: $inactive-opacity;
}

//TODO replace with proper markup
.clr:after {
  content: '';
  display: block;
  clear: both;
}

.prices {
  min-width: 80px;
  float: left;
}

.classes {
  width: 45px;
  float: right;
}

.inner {
  width: 150px;
  min-height: 50px;
}

.innerEditing {
  min-height: 80px;
}

.item {
  border: 1px solid #ebebeb;
  position: relative;
  width: 110px;
  padding: 5px 5px 20px;
}

.error {
  box-shadow: inset 0 0 0 1px #f00;
}

.conflictIconContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 3px;
  right: 3px;
}

.conflictIcon {
  fill: goldenrod;
}
