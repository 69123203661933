@import "ui";

body {
  margin: 0;
  padding: 0;

  #root {
    display: flex;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    overflow-y: scroll;
  }
}

.fare-classes {
  padding-top: 5px;
}

.fare-classes table {
    border-collapse: collapse;
    border-spacing: 0;
    margin-right: 30px;
}

.fare-classes .form-inline {
    margin-bottom: 5px;
}

.fare-classes .form-inline:after {
    clear: both;
    display: block;
    content: '';
}

.fare-classes-filter label,
.fare-classes-filter button {
    margin-right: 10px;
}

.fare-classes .fare-class-item .header td {
    border-top: none;
}

.fare-classes .fare-class-item .header .type {
    font-size: 1.2em;
    font-weight: bold;
}

/* hide sonata styles */
.fare-classes .fare-class-item .header:hover::after {
    visibility: hidden;
}

.fare-class-item {
    overflow: visible !important;
}

.price-action-delete {
    position: absolute;
    right: 4px;
    bottom: 18px;
    cursor: pointer;
    color: #d7d2d2;
    font-size: 17px;
}

.price-action-delete--deleted {
  color: #000;
}

.fare-class-item tr:hover .price-action-delete {
    color: #fff;
}

.fare-class-item td:hover .price-action-delete {
    color: #fd5b60;
}

.fare-class-item tbody tr:hover th,
.fare-class-item tbody tr:hover td {
    background-color: #f5f5f5;
}

.price.threshold {
    color: #01c8e5;
}

/*@import "bourbon";*/

.fare-class-item {
    margin-bottom: 80px;
    font-size: 11px;

    .title {
        font-weight: bold;
        text-align: right;
        border-top: none;
    }

    thead th.title {
        text-align: center;
        cursor: auto;
    }

    tbody th.title, tbody th.title > div {
        cursor: auto;
        width: 180px;
    }

    .caption {
        h2 {
            margin: 0;
        }

        label {
            width: auto;
            margin-left: 1em;
        }
    }
}


.table-header {
    background: white;
}

.table-header__title {
  width: 180px;
  text-align: center;
  position: sticky;
  top: 0;
  background: white;
  z-index: 5;
}

.table-row__title {
  position: sticky;
  left: 0;
  background: white;
  z-index: 4;
  font-size: 11px;
  min-width: 120px;
  padding-right: 5px;
  text-align: right;
  box-shadow: 4px 0 7px -3px #e8e8e8;
  max-width: 120px;
}

.matrix-full-view .table-header__title {
    box-shadow: 0 4px 7px -3px #e8e8e8;
}

.fare-class-item .table-header__fare-name {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.375em;
    height: 45px;
}

.fare-class-item .table-row__station-name {
    //
}

.matrix-easy-view .table-header__title {
    border: 1px solid white;
    border-top: none;
    border-bottom: none;
    background-color: #f9f9f9;
    padding-left: 10px;
    padding-right: 10px;
}

.flix-header__inner {
    margin: 0;
}
