.deactivate {
  position: absolute;
  left: 5px;
  bottom: 1px;
  font-size: 10px;
  cursor: pointer;
}

.input {
  margin-top: -1px;
}
