@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools';

.pageContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: center;
  align-self: center;
}

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  // TODO: fix this by updating HC and adding PageContainer component
  max-width: 992px;

  .imageLink {
    div[class*='hcr-img-link__content']::after {
      content: none;
    }

    div[class*='hcr-img-link__img'] {
      height: 300px;
    }
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: cssvar(spacing-sm) 0;
  }
}
